import React from 'react';

function Loading() {
  return (
    <section className='hero is-primary is-fullheight has-main-background'>
        <div className='hero-body has-text-centered'>
            <div className="container">
                <div className='columns'>
                    <div className='column'></div>
                    <div className='column'>
                        <progress className="progress is-small" max="100">60%</progress>
                    </div>
                    <div className='column'></div>
                </div>
            </div>
        </div>
    </section>
  );
}

export default Loading;