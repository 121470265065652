import React from 'react';
import { useState, useEffect } from 'react';

import './App.css';

function Navbar() {

  const [hidden, setHidden] = useState(true);

  const controlNavbar = () => {
    if (window.scrollY > window.innerHeight) {
      setHidden(false); 
    } else {
      setHidden(true);  
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', controlNavbar);
    return () => { // cleanup function
       window.removeEventListener('scroll', controlNavbar);
    };
  });

  return (
    <nav className={`navbar is-primary is-fixed-top ${hidden && 'navbar-is-hidden'}`} role="navigation" aria-label="main navigation">
        <div className='navbar-start'></div>
        <div className="navbar-brand">
          <div className='block'>
            <h4 className="title is-4 has-text-white is-family-secondary navbar-brand-padding">Fiorella & Carlos</h4>
          </div>
        </div>
        <div className='navbar-end'></div>
    </nav>
  );
}

export default Navbar;