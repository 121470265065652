import React from 'react';

const celebrationIcon = require('./icons/celebration-icon.png');
const weddingIcon = require('./icons/wedding-icon.png');

function Schedule() {
    return(
        <>
        <section className='hero is-primary is-fullheight'>
            <div className='hero-body has-text-centered'>
            <div className="container is-family-primary">
                <div className='section'>
                    <h2 className='title is-2 has-text-white has-text-weight-medium'>PROGRAMA</h2>
                </div>
                <div className='columns'>
                    <div className='column'>
                    <div className='container'>
                        <img className='is-96x96' src={ weddingIcon } alt=''></img>
                        <h3 className='title has-text-white is-3 has-text-weight-light'>12:00pm - Ceremonia Civil</h3>
                        <p>Oficiada en el gran salón de la Municipalidad de Magdalena</p>
                    </div>
                    </div>
                    <div className='column'>
                    <div className='container'>
                        <img className='is-96x96' src={ celebrationIcon } alt=''></img>
                        <h3 className='title has-text-white is-3 has-text-weight-light'>1:00 pm - Almuerzo de Celebración</h3>
                        <p>En los salones de Av. Brasil 3840 - Magdalena del Mar</p>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
        </>
    );
}

export default Schedule;