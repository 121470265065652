import React, { useEffect } from 'react';
import { useState } from 'react';

const song = require('./audio/Daft_Punk-Something_About_Us_Edited.mp3');

const photoOne = require('./photos/01.jpeg');
const photoTwo = require('./photos/02.jpeg');
const photoThree = require('./photos/03.jpeg');
const photoFour = require('./photos/04.jpeg');
const photoFive = require('./photos/05.jpeg');
const photoSix = require('./photos/06.jpeg');
const photoSeven = require('./photos/07.jpeg');
const photoEight = require('./photos/08.jpeg');
const photoNine = require('./photos/09.jpeg');
const photoTen = require('./photos/10.jpeg');


function MainContent() {

    const [showPlayer, setShowPlayer] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    let audio = new Audio(song)
    audio.volume = 0.1

    const start = () => {
        if (!isPlaying) {
            audio.play(); 
            document.getElementById('gallery')?.scrollIntoView({ behavior: 'smooth' });
            setIsPlaying(true);
        }
    }

    useEffect(() => {
        const toRef = setTimeout(() => {
            setShowPlayer(true);
            clearTimeout(toRef);
          }, 3000);
    }, [showPlayer]);

    return(
        <>
        <section className='hero is-primary is-fullheight has-main-background'>
            <div className='hero-body has-text-centered'>
                <div className="container">
                    <h1 className="title is-1 has-text-white is-family-secondary"> Fiorella & Carlos</h1>
                    <p className="subtitle has-text-white has-text-weight-medium">11.10.24</p>
                    { 
                        showPlayer && 
                        <button className='button is-white is-outlined is-rounded' onClick={ start }>Inicio</button> 
                    }
                </div>
            </div>
        </section>
        <section className='section' id='gallery'>
            <div className='grid has-4-cols'>
                <div className='cell'>
                    <div className='card'>
                        <div className='card-image'>
                            <figure className='image is-square'>
                                <img className='is-image-in-grid' src={ photoTwo } alt=''></img>
                            </figure>
                        </div>
                    </div>
                </div>
                <div className='cell'>
                    <div className='card'>
                        <div className='card-image'>
                            <figure className='image is-square'>
                                <img className='is-image-in-grid' src={ photoFour } alt=''></img>
                            </figure>
                        </div>
                    </div>
                </div>
                <div className='cell'>
                    <div className='card'>
                        <div className='card-image'>
                            <figure className='image is-square'>
                                <img className='is-image-in-grid' src={ photoOne } alt=''></img>
                            </figure>
                        </div>
                    </div>
                </div>
                <div className='cell'>
                    <div className='card'>
                        <div className='card-image'>
                            <figure className='image is-square'>
                                <img className='is-image-in-grid' src={ photoFive } alt=''></img>
                            </figure>
                        </div>
                    </div>
                </div>
                <div className='cell'>
                    <div className='card'>
                        <div className='card-image'>
                            <figure className='image is-square'>
                                <img className='is-image-in-grid' src={ photoNine } alt=''></img>
                            </figure>
                        </div>
                    </div>
                </div>
                <div className='cell'>
                    <div className='card'>
                        <div className='card-image'>
                            <figure className='image is-square'>
                                <img className='is-image-in-grid' src={ photoTen } alt=''></img>
                            </figure>
                        </div>
                    </div>
                </div>
                <div className='cell'>
                    <div className='card'>
                        <div className='card-image'>
                            <figure className='image is-square'>
                                <img className='is-image-in-grid' src={ photoThree } alt=''></img>
                            </figure>
                        </div>
                    </div>
                </div>
                <div className='cell'>
                    <div className='card'>
                        <div className='card-image'>
                            <figure className='image is-square'>
                                <img className='is-image-in-grid' src={ photoSeven } alt=''></img>
                            </figure>
                        </div>
                    </div>
                </div>
                <div className='cell'>
                    <div className='card'>
                        <div className='card-image'>
                            <figure className='image is-square'>
                                <img className='is-image-in-grid' src={ photoSix } alt=''></img>
                            </figure>
                        </div>
                    </div>
                </div>
                <div className='cell'>
                    <div className='card'>
                        <div className='card-image'>
                            <figure className='image is-square'>
                                <img className='is-image-in-grid' src={ photoEight } alt=''></img>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='hero is-success has-secondary-background'>
            <div className='hero-body has-text-centered'>
            <div className="container is-family-primary">
                <div className='section'>
                <h2 className="title is-3 has-text-weight-light is-family-primary">TENEMOS EL HONOR DE INVITARTE A CELEBRAR NUESTRA BODA.</h2>
                <h2 className="title is-3 has-text-weight-light is-family-primary">ACOMPÁÑANOS EL DÍA.</h2>
                
                </div>
                <div className='section'>
                <h1 className='title is-2 has-text-weight-medium'>VIERNES 11 DE OCTUBRE - 12:OO PM</h1>
                </div>
                <div className='section'>
                    <h2 className="title is-2 has-text-weight-medium is-family-primary">CEREMONIA CIVIL</h2>
                    <h4 className='title is-3 has-text-weight-light'>Municipalidad de Magdalena</h4>
                    <h5 className='title is-4 has-text-weight-light'>Av. Brasil 3501 - Magdalena del Mar<br/>
                    Lima - Peru</h5>
                </div>
                <div className='section'>
                <h2 className="title is-3 has-text-weight-medium">DRESS CODE</h2>
                <h5 className='title is-4 has-text-weight-light'>Elegante / Formal</h5>
                <h6 className='title is-5 has-text-weight-medium'>* No se proporcionará estacionamiento.</h6>
                </div>
            </div>
            </div>
        </section>
        </>
    );
}

export default MainContent;