import React from 'react';

function GiftList() {
    return(
        <>
        <section className='hero is-success is-fullheight has-secondary-background'>
            <div className='hero-body has-text-centered'>
            <div className="container is-family-primary">
            <h2 className='title is-1 has-text-weight-medium'>MESA DE REGALOS</h2>
            <h3 className='title is-4 has-text-weight-light'>Tu presencia en esta importante fecha es nuestro mejor regalo, pero si deseas obsequiarnos algo, puedes hacerlo en:</h3>
                <br/><br/>
                <div className='card bordered'>
                <div className='card-content'>
                    <h2 className='title is-3 has-text-weight-light'>Codigo de Lista de Novios Falabella</h2>
                    <h2 className='title is-3 has-text-weight-semibold'>709107-09</h2>
                    <a href='https://www.noviosfalabella.com.pe/public/inicio.do' target='_blank' rel="noreferrer" className='button is-white title is-4 has-text-weight-semibold'>
                        Acceder a la Lista
                    </a>
                    <p className='subtitle is-6'><br/>* Al ingresar, colocar el código en el campo "Encuentra la lista" y luego hacer click en "REGALAR".</p>
                </div>
                </div>
                <div className='card bordered'>
                <div className='card-content'>
                <div className='columns'>
                    <div className='column'>
                    <div className='container'>
                        <h3 className='title is-3 has-text-weight-light'>Cuenta BBVA</h3>
                        <h2 className='title is-4 has-text-weight-medium'>Corriente: 0011-0814-0100532420</h2>
                        <h2 className='title is-4 has-text-weight-medium'>Interbancaria: 011-814-000100532420-15</h2>
                    </div>
                    </div>
                    <div className='column'>
                    <div className='container'>
                        <h3 className='title is-3 has-text-weight-light'>Cuenta BCP</h3>
                        <h2 className='title is-4 has-text-weight-medium'>Soles: 5155643664025</h2>
                        <h2 className='title is-4 has-text-weight-medium'>Interbancaria: 00251500564366402556</h2>
                    </div>
                    </div>
                </div>
                </div>
                </div>
            </div>
            </div>
        </section>
        </>
    );
}

export default GiftList;