import React from 'react';
import { useState, useEffect } from 'react';

import Navbar from './Navbar';
import Loading from './Loading';

import MainContent from './components/main/MainContent';
import Schedule from './components/schedule/Schedule';
import GiftList from './components/GiftList';
import RSVPForm from './components/RSVP/RSVPForm';


function Home() {

  const [isLoading, setIsLoading] = useState(true);

  const loadingContent = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    loadingContent()
  });

  return (
    <div className='content'>
      { isLoading ? (
        <Loading />
      ) : (
        <>
          <Navbar/>
          <MainContent />
          <Schedule />
          <GiftList />
        </>
      )}
    </div>
  );
}

export default Home;
